<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
    >
      <organization-selector
        :allowNone="true"
        :organization="salesDelivery.organization.id"
        :filterable="true"
        :showAll="false"
        :disabled="!!salesDelivery.id"
        @organizationChanged="
          (organizationId) => {
            salesDelivery.organization.id = organizationId;
            salesDelivery.allowedLocations = [];
            salesDelivery.recipient.id = null;
            salesDelivery.salesOrder.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="salesDelivery.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="salesDelivery.organization.id"
        @locationsChanged="
          (locations) => {
            salesDelivery.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SALES_ORDERS)"
      :label="`${$t('COMMON.SALES_ORDERS')} (*)`"
      :placeholder="$t('COMMON.SALES_ORDERS')"
    >
      <sales-order-selector
        :allowNone="true"
        :salesOrder="
          salesDelivery.salesOrder ? salesDelivery.salesOrder.id : null
        "
        :filterable="true"
        :showAll="false"
        :disabled="!!salesDelivery.id"
        :filterOrganization="salesDelivery.organization.id"
        :filterStatus="ORDER_STATUS_VALIDATED"
        @salesOrderChanged="
          (salesOrderId, salesOrder) => {
            salesDelivery.salesOrder.id = salesOrderId;
            if (salesOrder) {
              salesDelivery.recipient.id = salesOrder.recipient.id;
              salesDelivery.delivery_entity_type =
                salesOrder.billing_entity_type;
              salesDelivery.delivery_firstname = salesOrder.billing_firstname;
              salesDelivery.delivery_lastname = salesOrder.billing_lastname;
              salesDelivery.delivery_company_name =
                salesOrder.billing_company_name;
              salesDelivery.delivery_email = salesOrder.billing_email;
              salesDelivery.delivery_country = salesOrder.billing_country;
              salesDelivery.delivery_state = salesOrder.billing_state;
              salesDelivery.delivery_city = salesOrder.billing_city;
              salesDelivery.delivery_zipcode = salesOrder.billing_zipcode;
              salesDelivery.delivery_address = salesOrder.billing_address;

              if (salesOrder.sourceWarehouse) {
                salesDelivery.sourceWarehouse.id =
                  salesOrder.sourceWarehouse.id;
              }
            }
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.salesOrder" />

    <base-input
      :label="`${$t('SALES_DELIVERIES.EXPIRATION_TIME')} (*)`"
      :placeholder="$t('SALES_DELIVERIES.EXPIRATION_TIME')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          minDate: 'today',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="salesDelivery.expiration_time"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.expiration_time" />

    <base-input :label="`${$t(`SALES_DELIVERIES.SELECT_CUSTOMERS`)} (*)`">
      <customer-selector
        :allowNone="true"
        :customer="salesDelivery.recipient.id"
        :filterable="true"
        :showAll="false"
        :filterOrganization="salesDelivery.organization.id"
        :disabled="!!salesDelivery.id"
        @customerChanged="
          (customerId, customer) => {
            salesDelivery.recipient.id = customerId;
            if (customer) {
              salesDelivery.delivery_entity_type = customer.customer_type;
              salesDelivery.delivery_firstname = customer.firstname;
              salesDelivery.delivery_lastname = customer.lastname;
              salesDelivery.delivery_company_name = customer.company_name;
              salesDelivery.delivery_email = customer.email;
              salesDelivery.delivery_country = customer.country;
              salesDelivery.delivery_state = customer.state;
              salesDelivery.delivery_city = customer.city;
              salesDelivery.delivery_zipcode = customer.zipcode;
              salesDelivery.delivery_address = customer.address;
            }
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.recipient" />

    <base-input :label="`${$t(`COMMON.SOURCE_WAREHOUSE`)}`">
      <warehouse-selector
        :allowNone="true"
        :warehouse="
          salesDelivery.sourceWarehouse
            ? salesDelivery.sourceWarehouse.id
            : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="salesDelivery.organization.id"
        :disabled="!!salesDelivery.id"
        @warehouseChanged="
          (warehouseId) => {
            salesDelivery.sourceWarehouse.id = warehouseId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.sourceWarehouse" />

    <h2 class="mt-5">{{ $t("COMMON.NOTE") }}</h2>
    <html-editor v-model="salesDelivery.excerpt" @change="onFormChanged()">
    </html-editor>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <h2 class="mt-5">{{ $t("COMMON.BILLING_INFORMATIONS") }}</h2>
    <base-input
      :label="$t('COMMON.TYPE')"
      :placeholder="$t('COMMON.TYPE')"
      @change="onFormChanged"
    >
      <el-select name="Type" v-model="salesDelivery.delivery_entity_type">
        <el-option
          :value="BILLING_ENTITY_TYPE_INDIVIDUAL"
          :label="$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')"
        />
        <el-option
          :value="BILLING_ENTITY_TYPE_COMPANY"
          :label="$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')"
        />
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.delivery_entity_type" />

    <div
      class="row"
      v-if="
        salesDelivery.delivery_entity_type == BILLING_ENTITY_TYPE_INDIVIDUAL
      "
    >
      <div class="col">
        <base-input
          :label="$t('COMMON.FIRSTNAME')"
          v-model="salesDelivery.delivery_firstname"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.delivery_firstname" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.LASTNAME')"
          v-model="salesDelivery.delivery_lastname"
        />
        <validation-error :errors="apiValidationErrors.delivery_lastname" />
      </div>
    </div>

    <base-input
      :label="$t('COMMON.COMPANY_NAME')"
      v-model="salesDelivery.delivery_company_name"
      @change="onFormChanged"
      v-if="salesDelivery.delivery_entity_type == BILLING_ENTITY_TYPE_COMPANY"
    />
    <validation-error :errors="apiValidationErrors.delivery_company_name" />

    <base-input
      :label="$t('COMMON.EMAIL')"
      v-model="salesDelivery.delivery_email"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.delivery_email" />

    <div class="form-wrapper full mb-3">
      <addresses-selector
        @addressInputChangeChanged="
          (value) => {
            salesDelivery = {
              ...salesDelivery,
              delivery_country: value.country,
              delivery_state: value.state,
              delivery_city: value.city,
              delivery_city: value.city,
              delivery_zipcode: value.zipcode,
              delivery_address: value.address,
            };
            onFormChanged();
          }
        "
      />
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="$t('COMMON.COUNTRY')"
          :placeholder="$t('COMMON.COUNTRY')"
        >
          <country-selector
            :country="salesDelivery.delivery_country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                salesDelivery.delivery_country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.delivery_country" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.STATE')"
          :placeholder="$t('COMMON.STATE')"
        >
          <state-selector
            :country="salesDelivery.delivery_country"
            :state="salesDelivery.delivery_state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                salesDelivery.delivery_state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.delivery_state" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.CITY')"
          v-model="salesDelivery.delivery_city"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.delivery_city" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.ZIPCODE')"
          v-model="salesDelivery.delivery_zipcode"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.delivery_zipcode" />
      </div>
    </div>

    <base-input
      :label="$t('COMMON.ADDRESS')"
      v-model="salesDelivery.delivery_address"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.delivery_address" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          salesDelivery.id
            ? $t("SALES_DELIVERIES.EDIT_SALES_DELIVERY")
            : $t("SALES_DELIVERIES.ADD_SALES_DELIVERY")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import { ORDER_STATUS_VALIDATED } from "@/constants/orders";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import SalesOrderSelector from "@/components/SalesOrderSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import WarehouseSelector from "@/components/WarehouseSelector.vue";
import AddressesSelector from "@/components/AddressesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    AddressesSelector,
    OrganizationSelector,
    SalesOrderSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    CustomerSelector,
    CountrySelector,
    StateSelector,
    WarehouseSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["salesDeliveryData", "formErrors", "loading"],

  data() {
    let salesDeliveryData = { ...this.salesDeliveryData };
    salesDeliveryData = this.$fillUserOrganizationData(salesDeliveryData);

    return {
      salesDelivery: salesDeliveryData,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let salesDeliveryData = cloneDeep(this.salesDelivery);
      salesDeliveryData.expiration_time = moment(
        salesDeliveryData.expiration_time
      ).toISOString();
      salesDeliveryData = this.$fillUserOrganizationData(salesDeliveryData);
      if (!salesDeliveryData.salesOrder.id) {
        delete salesDeliveryData.salesOrder;
      }
      if (!salesDeliveryData.delivery_company_name) {
        salesDeliveryData.delivery_company_name = "N/A";
      }
      if (!salesDeliveryData.delivery_firstname) {
        salesDeliveryData.delivery_firstname = "N/A";
      }
      if (!salesDeliveryData.delivery_lastname) {
        salesDeliveryData.delivery_lastname = "N/A";
      }
      this.$emit("salesDeliveriesubmitted", salesDeliveryData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    salesDeliveryData(salesDeliveryData) {
      if (salesDeliveryData) {
        this.salesDelivery = {
          ...this.salesDelivery,
          ...cloneDeep(salesDeliveryData),
        };
        if (!this.salesDelivery.organization) {
          this.salesDelivery.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
