var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.salesDelivery.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.salesDelivery.id},on:{"organizationChanged":(organizationId) => {
          _vm.salesDelivery.organization.id = organizationId;
          _vm.salesDelivery.allowedLocations = [];
          _vm.salesDelivery.recipient.id = null;
          _vm.salesDelivery.salesOrder.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.salesDelivery.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.salesDelivery.organization.id},on:{"locationsChanged":(locations) => {
          _vm.salesDelivery.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SALES_ORDERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SALES_ORDERS')} (*)`,"placeholder":_vm.$t('COMMON.SALES_ORDERS')}},[_c('sales-order-selector',{attrs:{"allowNone":true,"salesOrder":_vm.salesDelivery.salesOrder ? _vm.salesDelivery.salesOrder.id : null,"filterable":true,"showAll":false,"disabled":!!_vm.salesDelivery.id,"filterOrganization":_vm.salesDelivery.organization.id,"filterStatus":_vm.ORDER_STATUS_VALIDATED},on:{"salesOrderChanged":(salesOrderId, salesOrder) => {
          _vm.salesDelivery.salesOrder.id = salesOrderId;
          if (salesOrder) {
            _vm.salesDelivery.recipient.id = salesOrder.recipient.id;
            _vm.salesDelivery.delivery_entity_type =
              salesOrder.billing_entity_type;
            _vm.salesDelivery.delivery_firstname = salesOrder.billing_firstname;
            _vm.salesDelivery.delivery_lastname = salesOrder.billing_lastname;
            _vm.salesDelivery.delivery_company_name =
              salesOrder.billing_company_name;
            _vm.salesDelivery.delivery_email = salesOrder.billing_email;
            _vm.salesDelivery.delivery_country = salesOrder.billing_country;
            _vm.salesDelivery.delivery_state = salesOrder.billing_state;
            _vm.salesDelivery.delivery_city = salesOrder.billing_city;
            _vm.salesDelivery.delivery_zipcode = salesOrder.billing_zipcode;
            _vm.salesDelivery.delivery_address = salesOrder.billing_address;

            if (salesOrder.sourceWarehouse) {
              _vm.salesDelivery.sourceWarehouse.id =
                salesOrder.sourceWarehouse.id;
            }
          }
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.salesOrder}}),_c('base-input',{attrs:{"label":`${_vm.$t('SALES_DELIVERIES.EXPIRATION_TIME')} (*)`,"placeholder":_vm.$t('SALES_DELIVERIES.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        minDate: 'today',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.salesDelivery.expiration_time),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "expiration_time", $$v)},expression:"salesDelivery.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}}),_c('base-input',{attrs:{"label":`${_vm.$t(`SALES_DELIVERIES.SELECT_CUSTOMERS`)} (*)`}},[_c('customer-selector',{attrs:{"allowNone":true,"customer":_vm.salesDelivery.recipient.id,"filterable":true,"showAll":false,"filterOrganization":_vm.salesDelivery.organization.id,"disabled":!!_vm.salesDelivery.id},on:{"customerChanged":(customerId, customer) => {
          _vm.salesDelivery.recipient.id = customerId;
          if (customer) {
            _vm.salesDelivery.delivery_entity_type = customer.customer_type;
            _vm.salesDelivery.delivery_firstname = customer.firstname;
            _vm.salesDelivery.delivery_lastname = customer.lastname;
            _vm.salesDelivery.delivery_company_name = customer.company_name;
            _vm.salesDelivery.delivery_email = customer.email;
            _vm.salesDelivery.delivery_country = customer.country;
            _vm.salesDelivery.delivery_state = customer.state;
            _vm.salesDelivery.delivery_city = customer.city;
            _vm.salesDelivery.delivery_zipcode = customer.zipcode;
            _vm.salesDelivery.delivery_address = customer.address;
          }
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.recipient}}),_c('base-input',{attrs:{"label":`${_vm.$t(`COMMON.SOURCE_WAREHOUSE`)}`}},[_c('warehouse-selector',{attrs:{"allowNone":true,"warehouse":_vm.salesDelivery.sourceWarehouse
          ? _vm.salesDelivery.sourceWarehouse.id
          : null,"filterable":true,"showAll":false,"filterOrganization":_vm.salesDelivery.organization.id,"disabled":!!_vm.salesDelivery.id},on:{"warehouseChanged":(warehouseId) => {
          _vm.salesDelivery.sourceWarehouse.id = warehouseId;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.sourceWarehouse}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.salesDelivery.excerpt),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "excerpt", $$v)},expression:"salesDelivery.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_INFORMATIONS")))]),_c('base-input',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":_vm.onFormChanged}},[_c('el-select',{attrs:{"name":"Type"},model:{value:(_vm.salesDelivery.delivery_entity_type),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "delivery_entity_type", $$v)},expression:"salesDelivery.delivery_entity_type"}},[_c('el-option',{attrs:{"value":_vm.BILLING_ENTITY_TYPE_INDIVIDUAL,"label":_vm.$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')}}),_c('el-option',{attrs:{"value":_vm.BILLING_ENTITY_TYPE_COMPANY,"label":_vm.$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')}})],1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_entity_type}}),(
      _vm.salesDelivery.delivery_entity_type == _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.FIRSTNAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesDelivery.delivery_firstname),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "delivery_firstname", $$v)},expression:"salesDelivery.delivery_firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_firstname}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.LASTNAME')},model:{value:(_vm.salesDelivery.delivery_lastname),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "delivery_lastname", $$v)},expression:"salesDelivery.delivery_lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_lastname}})],1)]):_vm._e(),(_vm.salesDelivery.delivery_entity_type == _vm.BILLING_ENTITY_TYPE_COMPANY)?_c('base-input',{attrs:{"label":_vm.$t('COMMON.COMPANY_NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesDelivery.delivery_company_name),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "delivery_company_name", $$v)},expression:"salesDelivery.delivery_company_name"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_company_name}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EMAIL')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesDelivery.delivery_email),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "delivery_email", $$v)},expression:"salesDelivery.delivery_email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_email}}),_c('div',{staticClass:"form-wrapper full mb-3"},[_c('addresses-selector',{on:{"addressInputChangeChanged":(value) => {
          _vm.salesDelivery = {
            ..._vm.salesDelivery,
            delivery_country: value.country,
            delivery_state: value.state,
            delivery_city: value.city,
            delivery_city: value.city,
            delivery_zipcode: value.zipcode,
            delivery_address: value.address,
          };
          _vm.onFormChanged();
        }}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.COUNTRY'),"placeholder":_vm.$t('COMMON.COUNTRY')}},[_c('country-selector',{attrs:{"country":_vm.salesDelivery.delivery_country,"filterable":true,"showAll":false},on:{"countryChanged":(country) => {
              _vm.salesDelivery.delivery_country = country;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_country}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.STATE'),"placeholder":_vm.$t('COMMON.STATE')}},[_c('state-selector',{attrs:{"country":_vm.salesDelivery.delivery_country,"state":_vm.salesDelivery.delivery_state,"filterable":true,"showAll":false},on:{"stateChanged":(state) => {
              _vm.salesDelivery.delivery_state = state;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_state}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.CITY')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesDelivery.delivery_city),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "delivery_city", $$v)},expression:"salesDelivery.delivery_city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_city}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.ZIPCODE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesDelivery.delivery_zipcode),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "delivery_zipcode", $$v)},expression:"salesDelivery.delivery_zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_zipcode}})],1)]),_c('base-input',{attrs:{"label":_vm.$t('COMMON.ADDRESS')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesDelivery.delivery_address),callback:function ($$v) {_vm.$set(_vm.salesDelivery, "delivery_address", $$v)},expression:"salesDelivery.delivery_address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.delivery_address}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.salesDelivery.id ? _vm.$t("SALES_DELIVERIES.EDIT_SALES_DELIVERY") : _vm.$t("SALES_DELIVERIES.ADD_SALES_DELIVERY"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }